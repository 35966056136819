<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 464px">
      <div class="layer_head">
        <strong class="tit_popup">품명 조회</strong>
      </div>
      <div class="layer_body">
        <!-- 필터바 -->
        <div class="box_sorting">
          <strong class="screen_out">품명 조회하기</strong>
          <ul class="list_sorting">
            <li class="item_full">
              <!-- <em class="tit_txt">검색어</em> -->
              <div class="row_pack">
                <Input
                  style="width: 240px"
                  placeholder="검색어를 입력하세요"
                  :value.sync="searchKeyword"
                />
                <button type="button" class="btn_medium btn_primary" @click.prevent="onClickSearch">
                  검색
                </button>
              </div>
            </li>
          </ul>
        </div>
        <!-- 품명 리스트 -->
        <div class="tbl_comm tbl_list tbl_list_sm8 tbl_scroll">
          <div
            class="inner_scroll"
            style="height: calc(100vh - 544px); max-height: 294px; min-height: 49px"
          >
            <table>
              <colgroup>
                <col style="width: 52px" />
                <!-- 선택 -->
                <col />
                <!-- 품명 -->
              </colgroup>
              <thead>
                <tr>
                  <th style="width: 52px" />
                  <th style="width: 348px">품명</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in dataList"
                  :key="index"
                  :class="{ tr_select: isSelected(item) }"
                >
                  <td>
                    <div class="box_choice type_alone">
                      <div class="item_choice">
                        <!-- disabled 상태시 disabled 클래스 추가 -->
                        <input
                          :id="item.itemCode"
                          v-model="selectedGoodsNameSync"
                          type="radio"
                          class="inp_choice"
                          name="selectedCorporateCard"
                          :value="item"
                        />
                        <label class="lab_choice" :for="item.itemCode">
                          <span class="ico_account ico_rdo" />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <Dialouge>
                      <template v-slot:dialogueHead>
                        <span class="txt_dialogue">{{ item.itemName }}</span>
                      </template>
                      <template v-slot:dialogueBody>
                        <strong class="tit_dialogue">{{ item.itemName }}</strong>
                        <dl class="info_dialogue">
                          <dt>가격 :</dt>
                          <dd>{{ item.totalPrice ? item.totalPrice : "-" }}</dd>
                          <dt>입고처 :</dt>
                          <dd>
                            {{ item.partnerName ? item.partnerName : "-" }}
                          </dd>
                          <dt>보증기간 :</dt>
                          <dd>
                            {{ item.warranty ? item.warranty + "개월" : "-" }}
                          </dd>
                          <dt>자산상태 :</dt>
                          <dd>
                            {{ item.assetStatus ? item.assetStatus : "-" }}
                          </dd>
                        </dl>
                      </template>
                    </Dialouge>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            취소
          </button>
          <button
            type="button"
            class="btn_medium btn_secondary"
            :disabled="isDisabledMoveBtn"
            @click.prevent="onClickSelected"
          >
            선택
          </button>
          <!-- <button v-if="isBillSeleted" type="button" class="btn_medium btn_primary" :disabled="isDisabledSeletedBtn" @click.prevent="onClickSelected">선택</button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Input from "@/_approval/components/common/input/Input";
import Dialouge from "@/_approval/components/common/dialouge/Dialouge";
import ApiService from "@/services/ApiService";

export default {
  name: "PopGoodsNameSearch",
  components: {
    Input,
    Dialouge,
  },
  props: {
    selectedGoodsName: Object,
  },
  data() {
    return {
      searchKeyword: "",
      dataList: [],
      selectedGoodsNameSync: {}, // 선택한 품명 데이터
    };
  },
  computed: {
    isDisabledMoveBtn() {
      return !this.selectedGoodsNameSync.itemCode;
    },
  },
  mounted() {
    this.getDataList();
    this.selectedGoodsNameSync = this.selectedGoodsName;
    // this.getDeptInfo();
  },
  methods: {
    async getDataList(itemName) {
      let path = "";
      if (itemName) {
        path = `${this.$apiPath.CMDB_CATEGORY}?itemName=${itemName}`;
      } else {
        path = `${this.$apiPath.CMDB_CATEGORY}`;
      }

      const res = await ApiService.shared.getData(path);
      if (res.data) {
        this.dataList = res.data;
      }
    },
    onClickSearch() {
      this.getDataList(this.searchKeyword);
    },
    isSelected(item) {
      return this.selectedGoodsNameSync.itemCode == item.itemCode;
    },
    onClickSelected() {
      this.$emit("onClickComplete", this.selectedGoodsNameSync);
    },
  },
};
</script>
